import React from 'react'
import { FiGift } from "react-icons/fi";
import { MdOutlinePayment } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { FaHeadset } from "react-icons/fa";

const AfterBanner = () => {
  return (
    <div className='container mx-auto my-6'>
        <div className='container mx-auto px-10 py-10 '>
                <div className='grid grid-cols-1  justify-between items-center md:grid-cols-4 gap-4 '>
                    <div className='grid gap-1 bg-white lg:w-60 p-3 text-center rounded-tl-3xl rounded-br-3xl shadow-lg hover:scale-105 transition-all ease-in-out'>
                        <FiGift size={50} className='text-blue-500 ml-24 ' />
                        <p className='text-xl md:text-2xl font-bold text-black'>Great offers</p>
                        <h4 className='text-black'>Millions of things to choose from ongoing sales of customized goods.</h4>
                    </div>
                    <div className='grid gap-1 bg-white lg:w-60 p-3 text-center rounded-tl-3xl rounded-br-3xl shadow-lg hover:scale-105 transition-all ease-in-out'>
                        <MdOutlinePayment size={50} className='text-blue-500 ml-24' />
                        <p className='text-xl md:text-2xl font-bold text-black'>Secure payment</p>
                        <h4 className='text-black'>Use well-known and safe payment options to make your purchase.</h4>
                    </div>
                    <div className='grid gap-1 bg-white lg:w-60 p-3 text-center rounded-tl-3xl rounded-br-3xl shadow-lg hover:scale-105 transition-all ease-in-out'>
                        <TbTruckDelivery size={50} className='text-blue-500 ml-24' />
                        <p className='text-xl md:text-2xl font-bold text-black'>Fast delivery</p>
                        <h4 className='text-black'>We offer fast and reliable delivery services to ensure your products reach you on time.</h4>
                    </div>
                    <div className='grid gap-1 bg-white lg:w-60 p-3 text-center rounded-tl-3xl rounded-br-3xl shadow-lg hover:scale-105 transition-all ease-in-out'>
                        <FaHeadset size={50} className='text-blue-500 ml-24' />
                        <p className='text-xl md:text-2xl font-bold text-black'>24/7 support</p>
                        <h4 className='text-black'>Easy online chat and consultation to address your issue.</h4>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default AfterBanner