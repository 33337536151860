import React, { useEffect, useState } from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'
import Recommendations from '../components/Recomdation'
import { useSelector } from 'react-redux'
import PromotionalBanners from '../components/PromotionalBanners'
import CountDown from '../components/CountDown'
import axios from 'axios'
import SummaryApi from '../common'
import HeadPhoneBanner from '../components/banners/HeadPhoneBanner'
import WatchesBanner from '../components/banners/WatchesBanner'
import CategoryListDisplay from '../components/CategoryListDisplay'
import './HomeLoader.css'
import HeroSection from '../components/HeroSection'
import AfterBanner from '../components/AfterBanner'

const Home = () => {
  const user = useSelector(state => state?.user?.user);
  const [promotedProducts, setPromotedProducts] = useState([]);
  const [earliestEndDate, setEarliestEndDate] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchPromotedProducts = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await axios.get(SummaryApi.getPromotedProducts.url);
        const products = response.data;

        // Check if products are returned
        if (products.length === 0) {
          setLoading(false); // Set loading to false if no products are found
          return; // Exit if no products are found
        }

        setPromotedProducts(products);

        // Calculate the earliest end date
        const endDate = products.reduce((earliest, product) => {
          const endDate = new Date(product.promotion.endDate);
          return endDate < earliest ? endDate : earliest;
        }, new Date(products[0]?.promotion.endDate));

        setEarliestEndDate(endDate);
      } catch (error) {
        console.error("Error fetching promoted products:", error);
      } finally {
        setLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchPromotedProducts();
  }, []);

  // Check if the sale has ended
  const hasSaleEnded = earliestEndDate && new Date() >= earliestEndDate;

  return (
    <div  className='container mx-auto p-4'>
      {/* Loading Indicator */}
      {loading ? (
        <div className="flex justify-center w-full items-center h-screen mx-auto z-50">
          <p className="homeLoader"></p>
        </div>
      ) : (
        <>
          {/* <div className='flex'>
            <BannerProduct />
          </div> */}
          <HeroSection />
          <AfterBanner/>
          {/* Conditionally render CountDown based on sale status */}
          {!hasSaleEnded && promotedProducts.length > 0 && <CountDown promotedProducts={promotedProducts} />}
          <CategoryListDisplay />
          <HeadPhoneBanner />
          <HorizontalCardProduct category="airpods & cases" heading="Top Airpods" />
          <HorizontalCardProduct category="watches" heading="Popular Watches" />
          <WatchesBanner />
          <VerticalCardProduct category="earphones & headphones" heading="Earphones & Headphones" />
          <VerticalCardProduct category="mouse & keyboard" heading="Mouse & Keyboard" />
          <VerticalCardProduct category="speakers" heading="Speakers" />
        </>
      )}
    </div>
  )
}
export default Home
