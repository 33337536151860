import React, { useContext, useEffect, useState, useRef } from 'react'
import SummaryApi from '../common'
import Context from '../context'
import displayGHSCurrency from '../helpers/displayCurrency'
import { MdDelete } from "react-icons/md";
import VerticalCardProduct from '../components/VerticalCardProduct';
import { useNavigate } from 'react-router-dom';
import { BsFillCartXFill } from "react-icons/bs";
import ViewedProductsDisplay from '../components/ViewedProductsDisplay';
import { useSelector } from 'react-redux';
import townDeliveryFees from '../helpers/townDeliveryFees';

const Cart = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const context = useContext(Context)
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate()
    const loadingCart = new Array(context.cartProductCount).fill(null)
    const summaryRef = useRef(null); // Reference for the cart summary
    const recommendationsRef = useRef(null); // Reference for the recommendations section
    const [isFixed, setIsFixed] = useState(false); // State to manage fixed position
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [shippingInfo, setShippingInfo] = useState({
        contactName: '',
        streetAddress: '',
        mobile1: '',
        mobile2: '',
        region: '',
        district: '',
        town: '', // Ensure this is included
    });

    const fetchData = async () => {
        const response = await fetch(SummaryApi.addToCartProductView.url,{
            method: SummaryApi.addToCartProductView.method,
            credentials: 'include',
            headers: {
                'content-type': 'application/json'
            }
        })

        const responseData = await response.json()
        
        if(responseData.success){
            setData(responseData.data)
        }
    }

    const handleloading = async() =>{
        await fetchData();
    }

    useEffect(() => {
        const loadData = async () => {
            setLoading(true); 
            await handleloading(); 
            setLoading(false); 
        };

        loadData(); 
    }, []);

    const increaseQty = async(id, qty) => {
        const response = await fetch(SummaryApi.updateCartProduct.url, {
            method: SummaryApi.updateCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify({
                _id: id,
                quantity: qty + 1
            })
        });

        const responseData = await response.json();

        if (responseData.success) {
            fetchData(); // Ensure data is fetched again to update the UI
        }
    }

    const decreaseQty = async(id, qty) => {
        if (qty >= 2) {
            const response = await fetch(SummaryApi.updateCartProduct.url, {
                method: SummaryApi.updateCartProduct.method,
                credentials: 'include',
                headers: {
                    "content-type": 'application/json'
                },
                body: JSON.stringify({
                    _id: id,
                    quantity: qty - 1
                })
            });

            const responseData = await response.json();

            if (responseData.success) {
                fetchData(); // Ensure data is fetched again to update the UI
                
            }
        }
    }

    const deleteCartProduct = async(id)=>{
        const response = await fetch(SummaryApi.deleteCartProduct.url,{
            method : SummaryApi.deleteCartProduct.method,
            credentials : 'include',
            headers : {
                "content-type" : 'application/json'
            },
            body : JSON.stringify(
                {   
                    _id : id,
                }
            )
        })

        const responseData = await response.json()

        if(responseData.success){
            fetchData()
            context.fetchUserAddToCart()
        }
    }

    const handleDeleteClick = (id) => {
        setProductToDelete(id);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (productToDelete) {
            deleteCartProduct(productToDelete);
            setShowDeleteModal(false);
            setProductToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
        setProductToDelete(null);
    };

    const totalQty = data.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
    const totalPrice = data.reduce((preve, curr) => preve + (curr.quantity * curr?.productId?.sellingPrice), 0);
    
    // Calculate total promotional discount
    const totalPromotionDiscount = data.reduce((total, curr) => {
        const promotionValue = curr?.productId?.promotion?.value; // Assuming promotionValue is the percentage
        const sellingPrice = curr?.productId?.sellingPrice;
        const promotionEndDate = new Date(curr?.productId?.promotion?.endDate); // Assuming endDate is in the promotion object

        // Check if the promotion is still valid
        if (promotionValue && sellingPrice && promotionEndDate > new Date()) {
            const discountAmount = (sellingPrice * promotionValue) / 100; // Calculate discount
            return total + (discountAmount * curr.quantity); // Add to total discount
        }
        return total; // No valid promotion, return total as is
    }, 0);
    
    // Calculate total delivery fee based on the town
    const totalDeliveryFee = data.reduce((total, curr) => {
        const town = shippingInfo.town; // Get the town from shippingInfo
        const deliveryFee = townDeliveryFees[town] || 0; // Get the delivery fee for the town, default to 0 if not found
        return total + deliveryFee; // Add the delivery fee to the total
    }, 0);
    
    // Calculate final total price including delivery fee
    const finalTotalPrice = totalPrice + totalDeliveryFee - totalPromotionDiscount; // Include delivery fee and subtract promotion discount

    // Calculate the maximum free shipping threshold
    const freeShippingThresholds = data.map(product => product?.productId?.freeShippingThreshold || 0);
    const maxFreeShippingThreshold = Math.max(...freeShippingThresholds);
    
    // Check if any product qualifies for free shipping based on its own selling price
    const qualifiesForFreeShipping = data.map(product => ({
        id: product._id,
        qualifies: product?.productId?.sellingPrice >= product?.productId?.freeShippingThreshold && product?.productId?.freeShippingThreshold > 0,
        threshold: product?.productId?.freeShippingThreshold,
        sellingPrice: product?.productId?.sellingPrice,
    }));

    useEffect(() => {
        const handleScroll = () => {
            if (summaryRef.current && recommendationsRef.current) {
                const summaryRect = summaryRef.current.getBoundingClientRect();
                const recommendationsRect = recommendationsRef.current.getBoundingClientRect();

                // Check if the summary is in view and not overlapping with recommendations
                if (summaryRect.bottom > 0 && summaryRect.bottom < recommendationsRect.top) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (loading) return <div>
        <div className="flex justify-center items-center h-screen">
                <l-tail-chase
                size="40"
                speed="1.75" 
                color="black" 
            ></l-tail-chase>
        </div>
    </div>;

    return (
        <div className='container mx-auto p-4'>
            <div className='text-center text-lg '>
                {
                    data.length === 0 &&  (
                        <div className='bg-white py-5 justify-center items-center grid gap-1'>
                            <div className='bg-slate-200 flex justify-center ml-32 items-center text-blue-500 rounded-full w-24 h-24'>
                                <BsFillCartXFill size={50}/>
                            </div>
                        <p className='font-bold py-5'>Your cart is empty</p>
                        <p className=' py-5 -mt-4'>Please add some product to cart</p>
                        <div className='flex justify-center'>
                            <button onClick={() => navigate("/")} className='bg-blue-600 hover:bg-blue-700 text-white w-80 ml-8 mt-2 shadow-slate-500 shadow-md text-center p-1.5 rounded'>Shop Now</button>
                        </div>
                        </div>
                    )
                }
            </div>

            <div className='flex flex-col lg:flex-row gap-10 lg:justify-between'>
                {/* cart product */}
                <div className='w-full max-w-3xl bg-white h-full p-2'>
                    {
                        
                            data.map((product, index) => {
                                const { qualifies, threshold, sellingPrice } = qualifiesForFreeShipping[index];
                                return (
                                    <div className=' '>
                                        <div key={product?._id+"Add To Cart Loading"} className='w-full bg-white h-full my-2 border-t-2 p-2 border-slate-300  grid grid-cols-[128px,1fr]'>
                                            <div className='w-24 relative h-24 bg-white '>
                                            <img src={product?.productId?.productImage[0]} alt='' className='w-full h-full object-scale-down rounded mix-blend-multiply'/>
                                            </div>
                                            <div className=' '>
                                                <div className='px-4 py-2 relative'>
                                                    {/* product delete */}
                                                    <p className='absolute hidden md:block right-0 md:mr-2 lg:right-0 lg:mr-2 text-slate-600 font-semibold text-lg'>{displayGHSCurrency(product?.productId?.sellingPrice * product?.quantity)}</p>
                                                    <p className='absolute right-0 lg:right-0 ml-28 mt-4 lg:mt-6 lg:mr-2 text-red-600 font-bold text-lg'>{displayGHSCurrency(product?.productId?.sellingPrice)}</p>
                                                    <h2 className='text-sm  md:text-lg lg:text-xl text-ellipsis line-clamp-1'>{product?.productId?.productName}</h2>
                                                    <div className='lg:flex absolute items-center mt-3 gap-3 my-1'>
                                                        <p className='capitalize text-sm text-slate-500'>{product?.productId?.category}</p>
                                                        <p className='capitalize text-sm text-red-500'>Available :{product?.productId?.stock}</p>
                                                    </div>
                                                    {/* <div className='absolute mt-10 -mb-16  lg:block md:block'>
                                                        {threshold > 0 && !qualifies && (
                                                            <p className='text-red-600 text-sm'>
                                                                Add up to {displayGHSCurrency(threshold)} to qualify for free delivery!
                                                            </p>
                                                        )}
                                                    </div> */}
                                                    <div className='flex items-center justify-between'>
                                                        <div className='text-red-600 mt-14 rounded-full p-2 hover:bg-red-600 hover:text-white cursor-pointer' onClick={() => handleDeleteClick(product?._id)}>
                                                            <MdDelete />
                                                        </div>
                                                    </div>
                                                    <p className='-mt-7 hidden mr-96 ml-8 text-red-600 hover:text-red-700 cursor-pointer' onClick={() => handleDeleteClick(product?._id)}>REMOVE</p>

                                                    <div className='flex absolute right-0 mr-4 lg:mr-10 items-center -mt-10 gap-3 my-1'>
                                                        <button className='font-bold text-2xl pb-1 text-white bg-red-300 hover:bg-red-500 hover:text-white shadow-slate-500 shadow-sm w-7 h-7 flex justify-center items-center rounded' onClick={() => decreaseQty(product?._id, product?.quantity)}>-</button>
                                                        <span>{product?.quantity}</span>
                                                        <div className='' onClick={() => increaseQty(product?._id, product?.quantity)}>
                                                            <button className='font-bold text-2xl pb-1 text-white bg-red-600 hover:bg-red-700 hover:text-white shadow-slate-500 shadow-md w-7 h-7 flex justify-center items-center rounded' >+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                       
                    }
                    
                </div>

                {/* Cart Summary */}
                <div 
                    ref={summaryRef} 
                    className={`mt-5 lg:mt-0 lg:grid lg:right-4 w-full max-w-sm ${isFixed ? 'lg:fixed lg:top-20 lg:right-4' : ''} transition-all duration-300 ${isFixed ? 'lg:shadow-lg' : ''}`}
                >
                    {
                        loading ? (
                            <div className='h-36 bg-slate-200 animate-pulse rounded border border-slate-300'>
                            </div>
                        ) : (
                            <>
                                {data.length > 0 && ( // Check if there are products in the cart
                                    <>
                                    <div className='h-full bg-white rounded border border-slate-300'>
                                        <h2 className='text-white bg-red-600 text-lg px-4 py-1'>CART SUMMARY</h2>
                                        <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                            <p>Quantity:</p>
                                            <p>{totalQty}</p>
                                        </div>
                                        <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                            <p>Subtotal:</p>
                                            <p>{displayGHSCurrency(totalPrice)}</p>
                                        </div>
                                        <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                            <p>Promotion:</p>
                                            <p>{displayGHSCurrency(totalPromotionDiscount)}</p> {/* Display total promotion discount */}
                                        </div>
                                        <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600'>
                                            <p>Total:</p>
                                            <p>{displayGHSCurrency(finalTotalPrice)}</p>
                                        </div>
                                        <div className=''>
                                            <button onClick={() => navigate("/CheckOutPage")} className='bg-blue-600 hover:bg-blue-700 text-white w-80 ml-8  shadow-slate-500 shadow-md text-center p-1.5 rounded'>Checkout {displayGHSCurrency(finalTotalPrice)}</button>
                                        </div>
                                    </div>
                                    </>
                                )}

                            </>
                        )
                    }
                </div>
            </div>

            {/* Recommendations Section */}
            <div ref={recommendationsRef}>
                {data.length > 0 && (
                    (() => {
                        const categories = [...new Set(data.map(product => product?.productId?.category))];
                        return (
                            <div className='w-full'>
                                <VerticalCardProduct category={categories[0]} heading="Recommended Products" />
                                
                            </div>
                        )
                    })()
                )}
            </div>
            <ViewedProductsDisplay userId={user?._id} heading={"Recently Viewed"} />

            {/* Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this product from your cart?</p>
                        <div className="flex justify-end mt-4">
                            <button onClick={handleCancelDelete} className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Cancel</button>
                            <button onClick={handleConfirmDelete} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Yes, Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Cart
