import React, { useContext, useState, useRef, useEffect } from 'react'
import Logo from './Logo'
import { GrSearch } from "react-icons/gr";
import { FaRegUserCircle } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';
import { debounce } from '../utils/debounce'; // Import debounce function



const Header = ({ toggleTheme, theme }) => {
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false); // State for search input visibility
  const context = useContext(Context)
  const navigate = useNavigate()
  const searchInput = useLocation()
  const URLSearch = new URLSearchParams(searchInput?.search)
  const searchQuery = URLSearch.getAll("q")
  const [search, setSearch] = useState(searchQuery)
  const [suggestions, setSuggestions] = useState([]);

  const menuRef = useRef(null)
  const searchRef = useRef(null)

  const handleLogout = async () => {
    try {
        const response = await fetch(SummaryApi.logout_user.url, {
            method: SummaryApi.logout_user.method,
            credentials: "include"
        });

        const data = await response.json();

        if (data.success) {
            toast.success(data.message);
            dispatch(setUserDetails(null)); // Clear user details from Redux
            localStorage.removeItem('token'); // Clear token from local storage
            navigate("/"); // Redirect to home page
        } else {
            toast.error(data.message);
        }
    } catch (error) {
        console.error("Logout error:", error);
        toast.error("An error occurred while logging out.");
    }
  };

  const fetchSuggestions = async (query) => {
    if (!query) {
        setSuggestions([]);
        return;
    }
    try {
        console.log("Fetching suggestions for:", query); // Debugging log
        const response = await fetch(`${SummaryApi.searchProduct.url}?q=${query}`, {
            method: 'GET',
            credentials: 'include',
        });
        const result = await response.json();
        console.log("Suggestions fetched:", result); // Debugging log
        if (result.success) {
            setSuggestions(result.data.uniqueTags); // Set suggestions to uniqueTags
        } else {
            setSuggestions([]); // Clear suggestions if the fetch was not successful
        }
    } catch (error) {
        console.error('Error fetching search suggestions:', error);
    }
  };

  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    debouncedFetchSuggestions(value); // Call the debounced function
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (search) {
        navigate(`/search?q=${search}`);
        setSearch(''); // Clear the search input after navigating
        setSuggestions([]); // Clear suggestions after navigating
      } else {
        navigate("/");
      }
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/search?q=${tag}`); // Navigate to SearchProduct page with the selected tag
    setSearch(''); // Clear the search input
    setSuggestions([]); // Clear suggestions after navigating
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuDisplay(false);
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchVisible(false);
        setSuggestions([]); // Clear suggestions when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className='container h-24 lg:h-16 mb-20 lg:mb-0 shadow-md bg-white fixed w-full z-50'>
      <div className='h-full container mx-auto flex flex-col justify-between items-center px-4'>
        <div className='h-full container mx-auto flex justify-between items-center px-4'>
          <div className='flex-shrink-0'>
            <Link to="/">
              <Logo w={90} h={50}/>
            </Link>
          </div>

          {/* Theme Toggle Button */}
          {/* <button onClick={toggleTheme} className="bg-gray-200 rounded px-3 py-1">
            Toggle to {theme === 'light' ? 'Dark' : 'Light'} Mode
          </button> */}
          
          {/* Search Input for Desktop */}
          
            <div className='hidden lg:flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow pl-2 relative'>
              <input
                type='text'
                placeholder='search products, brands and categories'
                className='w-full outline-none'
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
                value={search}
              />
              <div className='text-lg min-w-[50px] h-8 bg-red-600 flex items-center justify-center rounded-r-full text-white'>
                <GrSearch />
              </div>

              {/* Suggestions Dropdown for Desktop */}
              {suggestions.length > 0 && (
                <div className="top-0 left-0 absolute bg-white border rounded shadow-lg mt-10 w-full" style={{ zIndex: 1000 }}>
                  {suggestions.map((tag, index) => (
                    <div key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => handleTagClick(tag)}>
                      <span>{tag}</span> {/* Ensure tag is a string */}
                    </div>
                  ))}
                </div>
              )}
            </div>
          

          
          <div className='flex items-center gap-4'>
            <div className='relative flex justify-center' ref={menuRef}>
              {
                user?._id && (
                    <div className='text-3xl cursor-pointer relative flex justify-center' onClick={() => setMenuDisplay(prev => !prev)}>
                    {
                    user?.profilePic ? (
                      <img src={user?.profilePic} alt={user?.name} className='w-10 h-10 rounded-full' />
                    ) : (
                      <FaRegUserCircle/>
                    )
                  }

                </div>
                )
              }
              

                {
                  menuDisplay && (
                    <div className='absolute bg-white bottom-0 top-11 h-fit z-50 p-2 shadow-lg rounded'>
                      <nav>
                        {
                          user?.role === ROLE.ADMIN && (
                            <Link to={"/admin-panel/all-products"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(prev => !prev)}>Admin Panel</Link>
                          )
                        }
                        {
                          user?.role === ROLE.GENERAL && (
                            <Link to={"/userprofile/my-profile"} className='whitespace-nowrap  md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(prev => !prev)}>My Profile</Link>
                          )
                        }
                      
                      </nav>
                </div>
                  )
                }
                
              
            </div>

            <Link to="/cart" className='text-2xl relative'>
              <span><FaShoppingCart/></span>

              {
                user?._id && (
                  <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                <p className='text-xs'>{context?.cartProductCount}</p>
              </div>
                )
              }
              
            </Link>


            <div>
              {
                user?._id ? (
                  <button onClick={handleLogout} className='bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-full'>Logout</button>
                ) : (
                  <Link to="/login" className='bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-full'>Login</Link>
                )
              }
            </div>

          </div>

        </div>
          <div>
              {/* Search Icon for Mobile */}
              <div className='lg:hidden md:hidden mb-2 flex items-center' ref={searchRef}>
                <div className='relative'>
                  <div className="w-full flex max-w-4xl mt-2">
                    <input
                      type='text'
                      placeholder='search products, brands and categories'
                      className='w-full outline-none border rounded-l-full px-2'
                      onChange={handleSearch}
                      onKeyDown={handleKeyDown}
                      value={search}
                    />
                    <div  className='text-lg min-w-[50px] h-8 bg-red-600 flex items-center justify-center  rounded-r-full text-white'>
                      <GrSearch />
                    </div>
                  </div>
                  {/* Suggestions Dropdown */}
                  {suggestions.length > 0 && (
                  <div className="absolute bg-white border rounded shadow-lg mt-auto top-0 w-56 lg:w-full" style={{ zIndex: 1000 }}>
                    {suggestions.map((tag, index) => (
                      <div key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => handleTagClick(tag)}>
                        <span>{tag}</span> {/* Ensure tag is a string */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        
      </div>
    </header>
  )

}

export default Header
