import React, { useState } from 'react';
import airpods from "../../assest/category/mobile.png"
import VerticalCardCategoryDisplay from '../../components/VerticalCardCategoryDisplay';

const MobilePages = () => {
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    
    // State for mobile pop-ups
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [showPricePopup, setShowPricePopup] = useState(false);
    const [showColorPopup, setShowColorPopup] = useState(false);

    const handleColorChange = (color) => {
        setSelectedColor(selectedColor === color ? '' : color);
    };

    const handleSortChange = (order) => {
        setSortOrder(order);
    };

    const applyFilters = () => {
        setShowSortPopup(false);
        setShowPricePopup(false);
        setShowColorPopup(false);
    };

    const resetFilters = () => {
        setMinPrice('');
        setMaxPrice('');
        setSelectedColor('');
        setSortOrder('');
        setShowSortPopup(false);
        setShowPricePopup(false);
        setShowColorPopup(false);
    };

    return (
        <div className='container mx-auto'>
            {/* Product Display */}
            <div className="flex mt-3 p-4 items-center justify-center bg-black text-white">
                <div className="">
                <img
                    src={airpods} // Replace with your product image
                    alt="Wireless Earbuds"
                    className="w-80 h-auto mx-auto"
                />
                </div>
                {/* Call to Action */}
                <div className="text-center mt-6">
                <h1 className="text-3xl md:text-5xl font-bold">
                    Next-level wireless freedom
                </h1>
                <p className="text-lg text-gray-300 mt-2">
                    with AI innovation
                </p>
                </div>
            </div>
            <div className='md:flex md:mt-10 md:gap-4'>
                {/* Mobile Filter Buttons */}
                <div className="block md:hidden p-4 overflow-x-auto flex space-x-2">
                    <button onClick={() => setShowSortPopup(true)} className="flex-shrink-0 bg-gray-800 text-white p-2 rounded">Sort By</button>
                    <button onClick={() => setShowPricePopup(true)} className="flex-shrink-0 bg-gray-800 text-white p-2 rounded">Price</button>
                    <button onClick={() => setShowColorPopup(true)} className="flex-shrink-0 bg-gray-800 text-white p-2 rounded">Colors</button>
                </div>

                {/* Side Panel for Filters (Desktop) */}
                <div className="hidden md:block w-1/4 p-4 h-full bg-gray-800 text-white rounded-lg">
                    <h2 className="text-2xl font-bold mb-4">Filters</h2>
                    
                    {/* Sort By */}
                    <h3 className="font-semibold mb-2">Sort By</h3>
                    <button className={`block w-full text-left p-2 hover:bg-gray-700 ${sortOrder === 'lowToHigh' ? 'bg-gray-700' : ''}`} onClick={() => handleSortChange('lowToHigh')}>Price: Low to High</button>
                    <button className={`block w-full text-left p-2 hover:bg-gray-700 ${sortOrder === 'highToLow' ? 'bg-gray-700' : ''}`} onClick={() => handleSortChange('highToLow')}>Price: High to Low</button>
                    <button className={`block w-full text-left p-2 hover:bg-gray-700 ${sortOrder === 'newest' ? 'bg-gray-700' : ''}`} onClick={() => handleSortChange('newest')}>Newest First</button>

                    {/* Price Range */}
                    <h3 className="font-semibold mt-4 mb-2">Price Range</h3>
                    <div className="flex mb-2">
                        <input 
                            type="number" 
                            placeholder="Min" 
                            value={minPrice} 
                            onChange={(e) => setMinPrice(e.target.value)} 
                            className="border rounded-l px-2 py-1 w-1/2 text-black"
                        />
                        <input 
                            type="number" 
                            placeholder="Max" 
                            value={maxPrice} 
                            onChange={(e) => setMaxPrice(e.target.value)} 
                            className="border rounded-r px-2 py-1 w-1/2 text-black"
                        />
                    </div>
                    <div className="flex flex-col">
                        <button className="block w-full text-left p-2 hover:bg-gray-700" onClick={() => { setMinPrice(''); setMaxPrice('100'); }}>Under $100</button>
                        <button className="block w-full text-left p-2 hover:bg-gray-700" onClick={() => { setMinPrice('100'); setMaxPrice('150'); }}> $100 - $150</button>
                        <button className="block w-full text-left p-2 hover:bg-gray-700" onClick={() => { setMinPrice('150'); setMaxPrice('200'); }}> $150 - $200</button>
                    </div>

                    {/* Color Selection */}
                    <h3 className="font-semibold mt-4 mb-2">Colors</h3>
                    <div className="flex flex-col">
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-700 ${selectedColor === 'black' ? 'bg-gray-700' : ''}`} 
                            onClick={() => handleColorChange('black')}
                        >
                            Black
                        </button>
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-700 ${selectedColor === 'white' ? 'bg-gray-700' : ''}`} 
                            onClick={() => handleColorChange('white')}
                        >
                            White
                        </button>
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-700 ${selectedColor === 'red' ? 'bg-gray-700' : ''}`} 
                            onClick={() => handleColorChange('red')}
                        >
                            Red
                        </button>
                    </div>
                </div>
                
                {/* Product Section */}
                <div className='mt-4 flex-1'>
                    <h1 className='text-lg font-bold mb-4'>Quality Airpods And Cases</h1>
                    <VerticalCardCategoryDisplay 
                        category="mobile" 
                        minPrice={minPrice} 
                        maxPrice={maxPrice} 
                        selectedColor={selectedColor} 
                        sortOrder={sortOrder} 
                    />
                </div>
            </div>

            {/* Sort Popup */}
            {showSortPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg w-3/4">
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-bold">Sort By</h2>
                            <button onClick={() => setShowSortPopup(false)} className="text-red-500">X</button>
                        </div>
                        <button onClick={() => handleSortChange('lowToHigh')} className={`block w-full text-left p-2 hover:bg-gray-200 ${sortOrder === 'lowToHigh' ? 'bg-gray-200' : ''}`}>Price: Low to High</button>
                        <button onClick={() => handleSortChange('highToLow')} className={`block w-full text-left p-2 hover:bg-gray-200 ${sortOrder === 'highToLow' ? 'bg-gray-200' : ''}`}>Price: High to Low</button>
                        <button onClick={() => handleSortChange('newest')} className={`block w-full text-left p-2 hover:bg-gray-200 ${sortOrder === 'newest' ? 'bg-gray-200' : ''}`}>Newest First</button>
                        <div className="flex justify-between mt-4">
                            <button onClick={resetFilters} className="bg-gray-300 p-2 rounded">Reset</button>
                            <button onClick={applyFilters} className="bg-blue-500 text-white p-2 rounded">Apply</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Price Popup */}
            {showPricePopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg w-3/4">
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-bold">Price Range</h2>
                            <button onClick={() => setShowPricePopup(false)} className="text-red-500">X</button>
                        </div>
                        <button onClick={() => { setMinPrice(''); setMaxPrice('100'); }} className="block w-full text-left p-2 hover:bg-gray-200">Under $100</button>
                        <button onClick={() => { setMinPrice('100'); setMaxPrice('150'); }} className="block w-full text-left p-2 hover:bg-gray-200">$100 - $150</button>
                        <button onClick={() => { setMinPrice('150'); setMaxPrice('200'); }} className="block w-full text-left p-2 hover:bg-gray-200">$150 - $200</button>
                        <div className="flex justify-between mt-4">
                            <button onClick={resetFilters} className="bg-gray-300 p-2 rounded">Reset</button>
                            <button onClick={applyFilters} className="bg-blue-500 text-white p-2 rounded">Apply</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Color Popup */}
            {showColorPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg w-3/4">
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-bold">Colors</h2>
                            <button onClick={() => setShowColorPopup(false)} className="text-red-500">X</button>
                        </div>
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-200 ${selectedColor === 'black' ? 'bg-gray-200' : ''}`} 
                            onClick={() => handleColorChange('black')}
                        >
                            Black
                        </button>
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-200 ${selectedColor === 'white' ? 'bg-gray-200' : ''}`} 
                            onClick={() => handleColorChange('white')}
                        >
                            White
                        </button>
                        <button 
                            className={`block w-full text-left p-2 hover:bg-gray-200 ${selectedColor === 'red' ? 'bg-gray-200' : ''}`} 
                            onClick={() => handleColorChange('red')}
                        >
                            Red
                        </button>
                        <div className="flex justify-between mt-4">
                            <button onClick={resetFilters} className="bg-gray-300 p-2 rounded">Reset</button>
                            <button onClick={applyFilters} className="bg-blue-500 text-white p-2 rounded">Apply</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobilePages;
