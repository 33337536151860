import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import productCategory from '../helpers/productCategory'
import SummaryApi from '../common'
import VerticalCard from '../components/VerticalCard'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoIosClose } from "react-icons/io";

const CategoryProduct = () => {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const urlSearch = new URLSearchParams(location.search)
    const urlCategoryListinArrary = urlSearch.getAll("category")

    

    const urlCategoryListObject = {}
    urlCategoryListinArrary.forEach(el => {
      urlCategoryListObject[el] = true
    })

    const [selectCategory, setSelectCategory] = useState(urlCategoryListObject)
    const [filterCategoryList, setFilterCategoryList] = useState([])
    const [sortBy, setSortBy] = useState()
    const [showSortModal, setShowSortModal] = useState(false) // State for sort modal
    const [showCategoryModal, setShowCategoryModal] = useState(false) // State for category modal

    const fetchData = async()=>{
      const response = await fetch(SummaryApi.filterProduct.url,{
        method : SummaryApi.filterProduct.method,
        headers : {
          "content-type" : "application/json",
        },
        body : JSON.stringify({
          category : filterCategoryList
        })
      })

      const dataResponse = await response.json()

      setData(dataResponse?.data || [])

    }

    const handleSelectCategory = (e)=>{
      const {name, value, checked} = e.target

      setSelectCategory((preve)=>{
        return{
          ...preve,
          [value] : checked
        }
      })
    }
    
    useEffect(()=>{
      fetchData()
    },[filterCategoryList])

    useEffect(()=>{
      const arrayOfCategory = Object.keys(selectCategory).map(categoryKeyName =>{
        if(selectCategory[categoryKeyName]){
          return categoryKeyName
        }

        return null
      }).filter(el => el)
    
      setFilterCategoryList(arrayOfCategory)

      const urlFormat = arrayOfCategory.map((el,index) =>{
        if((arrayOfCategory.length -1) === index){
          return `category=${el}`
        }
        return `category=${el}&&`
      })

      navigate("/product-category?"+urlFormat.join(""))

    },[selectCategory])

    const handleOnchangeSortBy = (e) =>{
      
      const { value } = e.target
      setSortBy(value)

      if(value === 'asc'){
        setData(preve => preve.sort((a,b)=>a.sellingPrice - b.sellingPrice))
      }

      if(value === 'dsc'){
        setData(preve => preve.sort((a,b)=>b.sellingPrice - a.sellingPrice))
      }
    }

    return (
        <div className='container mx-auto p-4'>
            {/* Responsive layout */}
            <div className='lg:grid grid-cols-[200px,1fr]'>
                {/* Left side (filter and sort) */}
                <div className='bg-white p-2 min-h-[calc(100vh-120px)] overflow-y-scroll scrollbar-none lg:block hidden'>
                    <div>
                        <h3 className='text-lg font-semibold text-slate-500 border-b p-1 border-slate-300'>Sort By</h3>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"asc"} checked={sortBy === 'asc'} onChange={handleOnchangeSortBy} />
                                <label>Price - Low to High</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"dsc"} checked={sortBy === 'dsc'} onChange={handleOnchangeSortBy} />
                                <label>Price - High to Low</label>
                            </div>
                        </form>
                    </div>

                    {/* Filter by category */}
                    <div>
                        <h3 className='text-lg font-semibold text-slate-500 border-b p-1 border-slate-300'>Category</h3>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            {productCategory.map((categoryName, index) => {
                                return (
                                    <div className='flex items-center gap-3' key={index}>
                                        <input type='checkbox' checked={selectCategory[categoryName?.value]} name={"category"} value={categoryName?.value} id={categoryName?.value} onChange={handleSelectCategory} />
                                        <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                </div>

                {/* Mobile View Buttons */}
            <div className='lg:hidden flex gap-2 ml-4 mt-4'>
                <button onClick={() => setShowSortModal(true)} className='bg-slate-200 text-black px-4 py-2 rounded flex items-center gap-2'>Sort By <IoMdArrowDropdown size={25}/></button>
                <button onClick={() => setShowCategoryModal(true)} className='bg-slate-200 text-black px-4 py-2 rounded flex items-center gap-2'>Category <IoMdArrowDropdown size={25}/></button>
            </div>

            {/* Sort Modal */}
            {showSortModal && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
                    <div className='bg-white p-4 rounded w-11/12 max-w-md'>
                        <div className='flex justify-between border-b p-1 border-slate-300'>
                            <h3 className='text-lg font-semibold text-slate-500 '>Sort By</h3>
                            <button onClick={() => setShowSortModal(false)} className='  text-red-600 px-4 py-2 rounded hover:text-red-800 hover:scale-110'><IoIosClose size={25}/></button>
                        </div>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"asc"} checked={sortBy === 'asc'} onChange={handleOnchangeSortBy} />
                                <label>Price - Low to High</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <input type='radio' name='sortBy' value={"dsc"} checked={sortBy === 'dsc'} onChange={handleOnchangeSortBy} />
                                <label>Price - High to Low</label>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Category Modal */}
            {showCategoryModal && (
                <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
                    <div className='bg-white p-4 rounded w-11/12 max-w-md'>
                        <div className='flex justify-between border-b p-1 border-slate-300'>
                            <h3 className='text-lg font-semibold text-slate-500 '>Category <span className='text-sm text-slate-400 font-normal'>(You can select multiple category)</span></h3>
                            <button onClick={() => setShowCategoryModal(false)} className='  text-red-600 px-4 py-2 rounded hover:text-red-800 hover:scale-110'><IoIosClose size={25}/></button>
                        </div>
                        <form className='text-sm flex flex-col gap-2 py-2'>
                            {productCategory.map((categoryName, index) => {
                                return (
                                    <div className='flex items-center gap-3' key={index}>
                                        <input type='checkbox' checked={selectCategory[categoryName?.value]} name={"category"} value={categoryName?.value} id={categoryName?.value} onChange={handleSelectCategory} />
                                        <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                    </div>
                )}

                {/* Right side (product list) */}
                <div className='flex flex-col lg:flex-row lg:px-4'>
                    <div className='flex-1'>
                        <p className='font-medium text-slate-800 text-lg my-2'>Filter Results : ({data.length} products found)</p>
                        <div className='lg:min-h-[calc(100vh-120px)] lg:overflow-y-scroll scrollbar-none lg:max-h-[calc(100vh-120px)]'>
                            {data.length !== 0 && (
                                <VerticalCard data={data} loading={loading} />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}

export default CategoryProduct
