import React from 'react'
import Slider from 'react-slick'
import airpods from '../assest/category/airpods.webp'
import phoneCase from '../assest/category/phonecover.webp'
import earphones from '../assest/category/earphone.png'
import watch from '../assest/category/watch.webp'
import battery from '../assest/category/battery.png'
import mouse from '../assest/category/mnkk.webp'
import protector from '../assest/category/screenprotector.webp'
import charger from '../assest/category/cable.png'
import gamepad from '../assest/category/gameController.png'
import extension from '../assest/category/extension-board.png'
import speaker from '../assest/category/speaker.webp'
import pendrive from '../assest/category/pendrive.webp'
import powerbank from '../assest/category/powerbank.png'
import mobile from '../assest/category/mobile.png'


const HeroSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "ease-in-out",
        pauseOnHover: false,
        pauseOnFocus: true,
    }
     const HeroData = [
        {
            id: 1,
            category: 'Airpods',
            title: 'Airpods',
            description: 'Lorem ipsum .',
            image: airpods,
        },
        {
            id: 2,
            category: 'Phone Case',
            title: 'Phone Case',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
            image: phoneCase,
        },
        {
            id: 3,
            category: 'Earphones',
            title: 'Earphones',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
            image: earphones,
        },
        {
            id: 4,
            category: 'Watch',
            title: 'Watch',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
            image: watch,
        },
        {
            id: 5,
            category: 'Battery',
            title: 'Battery',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
            image: battery,
        },
     ]
  return (
    <div className='lg:container mx-auto'>
        <div className='overflow-hidden min-h-[650px] lg:min-h-[550px] bg-gradient-to-br from-gray-300/80 to-slate-900 rounded-3xl p-10 flex items-center justify-center'>
            <div className='container lg:pb-8'>
                <Slider {...settings}>
                    {HeroData.map((item) => (
                        <div key={item.id} className='flex items-center justify-between'>
                            <div className='grid lg:grid-cols-1 grid-cols-2 '>
                                <div className='flex flex-col justify-center lg:gap-10 sm:pl-3 lg:pt-12 text-left order-1 lg:order-2 relative z-10'>
                                    <h1 className=' text-2xl font-bold'>{item.title}</h1>
                                    <h1 className=' text-3xl lg:text-[100px] font-bold uppercase text-white'>{item.category}</h1>
                                    <p className=' text-sm'>{item.description}</p>
                                </div>
                            </div>
                            <div>
                                <div className='order-2 lg:order-1 lg:ml-[600px] lg:-mt-40'>
                                    <img src={item.image} alt={item.category} className='w-[300px] h-[300px] scale-105 lg:scale-110 mx-auto object-contain drop-shadow-lg relative z-40' />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    </div>
  )
}

export default HeroSection