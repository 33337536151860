import React, { useEffect, useState } from 'react'
import airpods from '../assest/category/airpods.webp'
import phoneCase from '../assest/category/phonecover.webp'
import earphones from '../assest/category/earphone.png'
import watch from '../assest/category/watch.webp'
import battery from '../assest/category/battery.png'
import mouse from '../assest/category/mnkk.webp'
import protector from '../assest/category/screenprotector.webp'
import charger from '../assest/category/cable.png'
import gamepad from '../assest/category/gameController.png'
import extension from '../assest/category/extension-board.png'
import speaker from '../assest/category/speaker.webp'
import pendrive from '../assest/category/pendrive.webp'
import powerbank from '../assest/category/powerbank.png'
import mobile from '../assest/category/mobile.png'
import SummaryApi from '../common'
import { useNavigate } from 'react-router-dom'

const CategoryListDisplay = () => {
    const [categoryProduct, setCategoryProduct] = useState([])
    const navigate = useNavigate()

    const fetchCategoryProduct = async () => {
        const response = await fetch(SummaryApi.categoryProduct.url)
        const dataResponse = await response.json()
        console.log(dataResponse.data)
        setCategoryProduct(dataResponse.data)
    }

    useEffect(() => {
        fetchCategoryProduct()
    },[])
  return (
    <div className='container mx-auto mt-4 '>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 '>

            
                        <div className='py-10 pl-5 bg-gradient-to-b from-[#F1916D] to-[#F3DADF] rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="slide-right" className='text-white absolute mb-36 lg:mb-32 text-left'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold lg:font-semibold'>Quality</p>
                                <p className='text-lg lg:text-4xl font-bold lg:font-semibold'>Airpods</p>
                                <button onClick={() => navigate("/airpods&Case")} className='bg-[#413B61] hover:scale-105 transition-all cursor-pointer duration-300 text-white px-4 py-2 rounded-full mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={airpods} alt='airpods' className='absolute right-0 bottom-0 max-w-full  lg:min-w-1/2 h-48 lg:h-48 object-cover' />
                        </div>

                        <div className='py-10 pl-10 bg-gradient-to-b from-[#19305c] to-[#729ff3] rounded-2xl relative h-[320px] flex items-end'>
                            <img data-aos="zoom-in" src={phoneCase} sizes='20px' alt='airpods' className='absolute bottom-0 left-0 max-w-full min-w-1/2 h-64 object-cover' />
                            <div data-aos="fade-down" className='text-white right-5 absolute bottom-32 text-right'>
                                <p className='text-sm lg:text-lg text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-xl font-bold'>Quality</p>
                                <p className='text-lg lg:text-2xl font-bold'>Phone Cases</p>
                                <button onClick={() => navigate("/PhoneCases")} className='bg-[#F1916D] hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                        </div>

                        <div className='py-5 p-2 bg-gradient-to-b from-[#413b61] to-[#c5bcf8] rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="slide-right" className='text-white lg:mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-lg lg:text-4xl font-bold'>Earphones</p>
                                <button onClick={() => navigate("/EarPhones&HeadSet")} className='bg-[#413b61] hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={earphones} sizes='20px' alt='airpods' className='absolute bottom-0 right-0 max-w-full min-w-1/2 h-64 object-cover' />
                        </div>

                        <div className='py-5 p-2 bg-gradient-to-b from-[#ae7dac] to-[#fcdefb] rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="slide-right" className='text-white lg:mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-lg lg:text-4xl font-bold'>Watch</p>
                                <button onClick={() => navigate("/Watches")} className='bg-[#ae7dac] hover:scale-105 transition-all duration-300 cursor-pointer text-white px-4 py-2 rounded-full mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={watch} sizes='20px' alt='airpods' className='absolute bottom-0 mb-20 lg:mb-10 ml-5 lg:ml-10 max-w-full min-w-1/2 h-52 lg:h-64 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b  from-[#F1916D] to-[#F3DADF] rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-3xl font-bold'>Mouse &</p>
                                <p className='text-2xl lg:text-3xl font-bold'>Keyboard</p>
                                <button onClick={() => navigate("/Mouse&Keyboards")} className='bg-[#F1916D] hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={mouse} sizes='20px' alt='airpods' className='absolute bottom-14 ml-2 mb-24  -rotate-45 max-w-full min-w-1/2 h-40 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-slate-500 to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-2xl lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-5xl font-bold'>Battery</p>
                                <button onClick={() => navigate("/PhoneBattery")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={battery} sizes='20px' alt='airpods' className='absolute bottom-14 mb-20 ml-0 lg:h-48 -rotate-45 left-0 max-w-full min-w-1/2 h-40  object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-black to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-lg lg:text-5xl font-bold'>Screen Protector</p>
                                <button onClick={() => navigate("/ScreenProtector")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={protector} sizes='20px' alt='airpods' className='absolute bottom-14 mb-16 -ml-10  lg:left-0 max-w-full min-w-1/2 h-40 lg:h-48 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-yellow-500 to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right  lg:mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-5xl font-bold'>Chargers</p>
                                <button onClick={() => navigate("/PhoneChargers")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={charger} sizes='20px' alt='airpods' className='absolute lg:bottom-14 mb-20 lg:mb-0 ml-4 lg:-ml-0 lg:left-0 max-w-full min-w-1/2 h-48 lg:h-64 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-black to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="slide-right" className='text-white lg:mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-xl lg:text-4xl font-bold'>Gamepad</p>
                                <button onClick={() => navigate("/GameControllesrs")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img  data-aos="zoom-in" src={gamepad} sizes='20px' alt='airpods' className='absolute bottom-0 mb-20 lg:mb-20 ml-0 lg:mr-5  lg:right-0 max-w-full min-w-1/2 h-36 lg:h-48 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-blue-500 to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <img data-aos="zoom-in" src={extension} sizes='20px' alt='airpods' className='absolute lg:bottom-0 mb-0 ml-4 lg:ml-0 lg:-mb-0  lg:left-0 max-w-full min-w-1/2 h-20 lg:h-48 object-cover' />
                            <div data-aos="fade-down" className='text-white absolute lg:right-5 lg:text-right mb-28'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-xl lg:text-3xl font-bold'>Extension</p>
                                <p className='text-3xl lg:text-3xl font-bold'>Board</p>
                                <button onClick={() => navigate("/ExtensionBoards")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                        </div>

                        
                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-slate-500 to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right mb-3  lg:mb-4'>
                                <p className='text-sm lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-lg lg:text-2xl font-bold'>Quality</p>
                                <p className='text-xl lg:text-5xl font-bold'>Speakers</p>
                                <button onClick={() => navigate("/Speakers")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full mt-2 lg:mt-0 cursor-pointer'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={speaker} sizes='20px' alt='airpods' className='absolute lg:bottom-14 mb-28 -ml-3 lg:mb-14 -rotate-45 left-0 lg:ml-6 max-w-full min-w-1/2 h-44 lg:h-48 object-cover' />
                        </div>
            
                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-black to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right mb-4'>
                                <p className='text-lg lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-xl lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-5xl font-bold'>Drives</p>
                                <button onClick={() => navigate("/PenDrive&MemoryCards")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={pendrive} sizes='20px' alt='airpods' className='absolute lg:bottom-14 mb-24 lg:mb-10 left-0 max-w-full min-w-1/2 h-40 lg:h-52 object-cover' />
                        </div>
                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-black to-gray-800 rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right lg:ml-0 lg:mb-4'>
                                <p className='text-lg lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-xl lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-3xl font-bold'>Powerbank</p>
                                <button onClick={() => navigate("/PowerBanks")} className='bg-white hover:scale-105 transition-all duration-300 text-black px-4 py-2 rounded-full cursor-pointer mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={powerbank} sizes='20px' alt='airpods' className='absolute lg:bottom-14 mb-24 lg:mb-20 left-0 max-w-full min-w-1/2 h-40 lg:h-48 object-cover' />
                        </div>

                        <div className='py-5 p-2 lg:pl-10 bg-gradient-to-b from-yellow-500 to-gray-800  rounded-2xl relative h-[320px] flex items-end'>
                            <div data-aos="fade-up" className='text-white absolute right-5 text-right lg:ml-0 lg:mb-4'>
                                <p className='text-lg lg:text-xl text-gray-400'>Enjoy</p>
                                <p className='text-xl lg:text-2xl font-bold'>Quality</p>
                                <p className='text-2xl lg:text-3xl font-bold'>Mobile</p>
                                <button onClick={() => navigate("/Mobile")} className='bg-white hover:scale-105 cursor-pointer transition-all duration-300 text-black px-4 py-2 rounded-full mt-2'>Explore</button>
                            </div>
                            <img data-aos="zoom-in" src={mobile} sizes='20px' alt='mobile' className='absolute lg:bottom-14 mb-24 lg:mb-20 left-0 max-w-full min-w-1/2 h-40 lg:h-48 object-cover' />
                        </div>
                    

                      
            
       </div> 
    </div>
  )
}

export default CategoryListDisplay