import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FLogo from './FLogo';
import FooterCategoryList from './FooterCategoryList';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import scrollTop from '../helpers/scrollTop';
import '../components/test/subscribe.css'

const Footer = () => {
    const [email, setEmail] = useState('');
    const [acceptedPrivacy, setAcceptedPrivacy] = useState(false); 
    const navigate = useNavigate();

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (!acceptedPrivacy) {
            toast.error('You must accept the privacy policy to subscribe.'); 
            return;
        }

        try {
            const response = await fetch(SummaryApi.subscribeEmail.url, {
                method: SummaryApi.subscribeEmail.method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (data.success) {
                toast.success('Subscribed successfully!');
                setEmail(''); 
                setAcceptedPrivacy(false); 
            } else {
                toast.error('Subscription failed: ' + data.message);
            }
        } catch (error) {
            console.error('Error subscribing:', error);
        }
    };

    return (
        <footer className=' mt-10 '>
            <div className='container mx-auto px-10 py-5 bg-slate-800'>
                <div className='flex flex-col lg:py-7 lg:px-20 md:flex-row justify-between'>
                    <div className='grid gap-1'>
                        <div className='grid gap-4 p-4'>
                            <p className='text-md font-bold text-white'>New to Musto-Phones?</p>
                            <h4 className='text-white'>To receive information on our most recent offers, sign up for our newsletter!</h4>
                            <div id='subscribe'>
                                <div className='inputbox'>
                                <input
                                    type='email'
                                    name='email'
                                    className=''
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label>Email</label>
                                </div>
                            </div>
                            
                            <div className='flex gap-2'>
                                <input
                                    type='checkbox'
                                    className='text-black p-2 cursor-pointer hover:bg-slate-700'
                                    checked={acceptedPrivacy}
                                    onChange={() => setAcceptedPrivacy(!acceptedPrivacy)} // Toggle checkbox state
                                />
                                <p className='text-white text-sm'>I accept the privacy policies.</p>
                                
                            </div>
                            <h3 className='text-red-500 hover:underline cursor-pointer'>Privacy Policy</h3>
                            <button
                                    className='bg-white md:w-36 lg:w-36 w-full  text-black p-2 rounded-full cursor-pointer'
                                    onClick={handleSubscribe}
                                    disabled={!acceptedPrivacy} // Disable button if privacy not accepted
                                >
                                    Subscribe
                            </button>
                        </div>
                    </div>
                    <Link to="/" className='flex justify-center md:justify-start' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <FLogo w={100} h={50} />
                    </Link>
                </div>
            </div>

            <div className='container mx-auto px-4 pt-10 bg-slate-400'>
                <div className='grid md:flex lg:py-7 md:justify-between gap-4'>
                    <div className='flex gap-4 md:grid lg:flex lg:gap-10'>
                        <div className='flex flex-col gap-1'>
                            <p className='text-2xl font-bold text-black'>Join us on</p>
                            <div className='flex gap-4 cursor-pointer'>
                                <Link to='https://www.facebook.com/p/Musto-Phones-and-Accessories-100065346486646/' target='_blank'><FaFacebook className='text-2xl font-bold text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
                                <Link to='https://www.instagram.com/mustophones?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='_blank'><FaInstagram className='text-2xl font-bold text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
                                <Link to='https://www.twitter.com/musto.phones.9212' target='_blank'><FaXTwitter className='text-2xl font-bold text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
                                <Link to='https://wa.me/233553105219' target='_blank'><IoLogoWhatsapp className='text-2xl font-bold text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer' /></Link>
                            </div>
                        </div>
                        <div className='flex flex-col  gap-1 '>
                        <p className='text-xl font-bold text-black'>Need Help?</p>
                        <div className='grid gap-1 px-4'>
                            <div className='flex ' onClick={scrollTop}>
                                    <button onClick={() => navigate('chat-us')}  className='text-white hover:text-red-500 hover:underline'>Chat with us</button>
                            </div>
                            <div className='flex ' onClick={scrollTop}>
                                    <button onClick={() => navigate('help-center')} className='text-white hover:text-red-500 hover:underline'>Help Center</button>
                            </div>
                            
                            <div className='flex' onClick={scrollTop}>
                                    <button onClick={() => navigate('contact-us')} className='text-white hover:text-red-500 hover:underline'>Contact Us</button>
                            </div>
                        </div>
                    </div>
                    </div>              
                    <div>
                        <p className='text-xl mb-3 font-extrabold text-black'>Browse by category</p>
                        <FooterCategoryList />
                    </div>

                    <div className='grid '>
                        <div className='grid gap-1 px-4'>
                            <p className='text-xl font-bold text-black'>Locate Us</p>
                            <div className='flex gap-2'>
                                    <FaPhoneAlt className='text-2xl text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105' />
                                    <a href="tel:+233553105219" className='text-sm text-white'>+233 55 310 5219</a>
                            </div>
                            <div className='flex gap-2'>
                                    <FaEnvelope className='text-2xl text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105' />
                                    <p className='text-white'>info@musto-phones.com</p>
                            </div>
                            <div className='flex gap-2'>
                                    <FaMapMarkerAlt className='text-2xl text-white hover:text-red-500 transition duration-300 ease-in-out transform hover:scale-105' />
                                    <Link to={"https://maps.app.goo.gl/F3DiBUWX6C8D6QJo9"} target='_blank' className='text-white'>University of Cape Coast, Cape Coast, Ghana</Link>
                            </div>  
                        </div>
                    </div>
                    
                </div>

                <div className='mt-5'>
                    <p className='text-center font-bold text-black'>
                        &copy; 2024 MUSTO-PHONES. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;